var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SThread from "../SThread";
import { SUuid } from "../SUuid";
export default new /** @class */ (function () {
    function SNotification() {
    }
    SNotification.prototype._setInstance = function (ref) {
        this._Container = ref;
    };
    SNotification.prototype._createInstance = function (notification) {
        var _this = this;
        var NI = __assign(__assign({}, notification), { close: function () { return _this.remove(notification.key); } });
        return NI;
    };
    SNotification.prototype.send = function (notification) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (!_this._Container)
                return reject("SNotificationContainer not instance found.");
            if (!notification.key)
                notification.key = SUuid();
            _this._Container.state.data[notification.key] = notification;
            _this._Container.repaint();
            if (notification.time) {
                new SThread(notification.time, notification.key, false).start(function () {
                    _this.remove(notification.key);
                });
            }
            resolve(_this._createInstance(notification));
        });
    };
    SNotification.prototype.remove = function (key) {
        delete this._Container.state.data[key];
        this._Container.repaint();
    };
    SNotification.prototype.removeAll = function () {
        this._Container.state.data = {};
        this._Container.repaint();
    };
    return SNotification;
}())();
