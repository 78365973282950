import { SPage } from "servisofts-component";
import Model from "../../../Model";
import root from "./root";
import tbprd from "./tbprd";

export default SPage.combinePages("profile", {
    "": root,
    tbprd

})
