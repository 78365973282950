var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
// import { SUuid, SView } from "../../../index"
import ReactDOM from 'react-dom';
import SMarkerAbstract from './abstract';
var SMarker = /** @class */ (function (_super) {
    __extends(SMarker, _super);
    function SMarker(props) {
        return _super.call(this, props) || this;
    }
    SMarker.prototype.setCoordinate = function (_a) {
        var latitude = _a.latitude, longitude = _a.longitude;
        // @ts-ignore
        var overlay = this.props.overlay;
        if (overlay) {
            overlay.setPosition({
                lat: latitude,
                lng: longitude
            });
        }
    };
    SMarker.prototype.renderMap = function (child, _a, _toRemove) {
        var _b, _c;
        var map = _a.map, maps = _a.maps, key = _a.key;
        if (!maps)
            return;
        var CustomOverlay = /** @class */ (function (_super) {
            __extends(CustomOverlay, _super);
            function CustomOverlay(position) {
                var _this = _super.call(this) || this;
                _this.position = position;
                _this.div = document.createElement('div');
                _this.div.style.position = 'fixed';
                return _this;
                // this.div.style.zIndex = "99999"
                // this.div.style.transform = 'translate(-100%, -50%);';
                // this.div.style.backgroundColor = "#f0f";
            }
            CustomOverlay.prototype.onAdd = function () {
                var newElm = React.cloneElement(child, {
                    overlay: this,
                    ref: function (ref) {
                        if (child.ref) {
                            child.ref(ref);
                        }
                    }
                });
                ReactDOM.render(newElm, this.div);
                // this.div.innerHTML = `<div onClick='alert("hola")' style="z-index:99999; position:fixed;">CLICK</div>`
                var panes = this.getPanes();
                // panes.overlayLayer.onClick = () => {
                //     alert("Asdasd")
                // }
                panes.overlayMouseTarget.appendChild(this.div);
            };
            CustomOverlay.prototype.draw = function () {
                var overlayProjection = this.getProjection();
                var point = overlayProjection.fromLatLngToDivPixel(this.position);
                if (point) {
                    this.div.style.left = "".concat(point.x, "px");
                    this.div.style.top = "".concat(point.y, "px");
                }
            };
            CustomOverlay.prototype.onRemove = function () {
                if (this.div.parentNode) {
                    this.div.parentNode.removeChild(this.div);
                }
            };
            CustomOverlay.prototype.setPosition = function (position) {
                this.position = position;
                this.draw();
            };
            return CustomOverlay;
        }(maps.OverlayView));
        var overlay = new CustomOverlay({
            lat: (_b = this.state.latitude) !== null && _b !== void 0 ? _b : this.props.latitude,
            lng: (_c = this.state.longitude) !== null && _c !== void 0 ? _c : this.props.longitude
        });
        this.marker = overlay;
        // overlay.addListener('click', child.props.onPress);
        overlay.setMap(map);
        _toRemove.push(overlay);
        return null;
    };
    SMarker.prototype.render = function () {
        var _a;
        return React.createElement("div", { 
            // key={SUuid()}
            style: {
                // position: "fixed",
                cursor: "pointer",
                textAlign: "center",
                width: this.props.width,
                height: this.props.height,
                // background: "#00f",
                // zIndex: 9999999,
                transform: 'translate(-50%, -100%)'
            }, onClick: this.props.onPress }, (_a = this.props.children) !== null && _a !== void 0 ? _a : this._default());
    };
    return SMarker;
}(SMarkerAbstract));
export default SMarker;
