var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { View, TouchableOpacity, KeyboardAvoidingView, BackHandler } from 'react-native';
var SPopupComponent2 = /** @class */ (function (_super) {
    __extends(SPopupComponent2, _super);
    function SPopupComponent2(props) {
        var _this = _super.call(this, props) || this;
        _this.handleBackButton = function () {
            console.log("Deberia cerrar");
            _this.props.close();
            return true; // Esto previene que la aplicación cierre al presionar el botón atrás
        };
        _this.state = {};
        return _this;
    }
    SPopupComponent2.prototype.componentDidMount = function () {
        BackHandler.addEventListener('hardwareBackPress', this.handleBackButton);
    };
    SPopupComponent2.prototype.componentWillUnmount = function () {
        BackHandler.removeEventListener('hardwareBackPress', this.handleBackButton);
    };
    SPopupComponent2.prototype.getButonClose = function () {
        var _this = this;
        return React.createElement(TouchableOpacity, { style: {
                width: 30,
                height: 30,
                position: "absolute",
                top: 0,
                right: 0,
                justifyContent: "center",
                alignItems: "center"
            }, onPress: function () {
                _this.props.close();
            } });
    };
    SPopupComponent2.prototype.render = function () {
        var _this = this;
        return (React.createElement(TouchableOpacity, { activeOpacity: 1, onPress: function () {
                _this.props.close();
            }, style: {
                width: "100%",
                height: "100%",
                position: "absolute"
            } },
            React.createElement(KeyboardAvoidingView, { style: { flex: 1, flexDirection: 'column' }, behavior: "padding", enabled: true },
                React.createElement(View, { style: {
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center"
                    } }, this.props.children))));
    };
    return SPopupComponent2;
}(Component));
export default SPopupComponent2;
