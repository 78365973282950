import React from 'react';
import DPA, { connect } from 'servisofts-page';
import { Parent } from ".."
import Model from '../../../Model';
import item from '../item';
import { SNavigation } from 'servisofts-component';
// import item from './item';

class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            title: "Lista de " + Parent.name,
            item: item,
            //excludes: ['usest','iniciales','modpreven','idcentro','suctod','usultsuc','verval','selcob','autcred','pcpred','facauto','uimpr'],
            onRefresh: (resolve) => {
                Parent.model.Action.CLEAR();
                resolve();
            }
        });
    }
    $allowNew() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    }
    $allowTable() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }
    $filter(data) {
        return (data.prdest != "0") && data.empcod.startsWith(SNavigation.getParam("tipo"))
    //    return data.empcod.startsWith("V")

    }
    $getData() {
        return Parent.model.Action.getAll();
    }
}
export default connect(index);