var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Text, View, StyleSheet } from 'react-native';
import React, { Component } from 'react';
var styles = StyleSheet.create({
    item: {
        // height: ITEM_HEIGHT,
        // width: ITEM_WIDTH,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        justifyContent: "center",
        overflow: "hidden",
        borderColor: "#66666666"
    },
    text: {
        fontSize: 11,
        color: "#ffffff",
        borderWidth: 0
    }
});
var Cell = /** @class */ (function (_super) {
    __extends(Cell, _super);
    function Cell() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            select: false
        };
        return _this;
    }
    Cell.prototype.render = function () {
        return React.createElement(View, { style: [
                styles.item,
                {
                    width: this.props.col.item,
                    height: this.props.row.item
                }
            ] },
            React.createElement(Text, { style: styles.text }, this.props.value));
    };
    return Cell;
}(Component));
export default React.memo(Cell, function (prevProps, nextProps) { return prevProps.value === nextProps.value; });
