var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Text, View, FlatList } from 'react-native';
import React, { Component } from 'react';
import SView from '../SView';
var RowNumbers = /** @class */ (function (_super) {
    __extends(RowNumbers, _super);
    function RowNumbers() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        _this.headerLetter = function (_a) {
            var width = _a.width, val = _a.val;
            return;
        };
        return _this;
    }
    RowNumbers.prototype.scrollTo = function (y) {
        this.scroll.scrollToOffset({ offset: y, animated: false });
    };
    RowNumbers.prototype.getItemLayout = function (data, index) {
        return { length: this.props.parent.props.rowHeight, offset: this.props.parent.props.rowHeight * index, index: index };
    };
    RowNumbers.prototype.keyExtractor = function (item, index) {
        return "nr" + index;
    };
    RowNumbers.prototype.render = function () {
        var _this = this;
        var ItemRow = React.memo(function (_a) {
            var val = _a.val;
            return React.createElement(View, { style: {
                    width: "100%",
                    height: _this.props.parent.props.rowHeight,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRightWidth: 1,
                    borderBottomWidth: 1,
                    borderColor: "#666"
                } },
                React.createElement(Text, { style: {
                        fontSize: 11,
                        color: "#ffffff"
                    } }, val));
        }, function (prevProps, nextProps) { return prevProps.val != nextProps.val; });
        return (React.createElement(SView, { width: 40, height: true, backgroundColor: '#66666666' },
            React.createElement(FlatList, { data: this.props.parent.state.rows, ref: function (ref) { return _this.scroll = ref; }, scrollEnabled: false, scrollEventThrottle: this.props.parent.scrollEventThrottle, keyExtractor: this.keyExtractor, getItemLayout: this.getItemLayout.bind(this), renderItem: function (col) { return React.createElement(ItemRow, { val: col.index + 1 }); } }),
            React.createElement(SView, { height: 10 })));
    };
    return RowNumbers;
}(Component));
export default RowNumbers;
