var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import { Platform, Linking } from 'react-native';
import STheme from '../STheme/index';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Pages from '../../Pages/index';
import SThread from '../SThread';
import SLinking, { openURL } from './SLinking';
var Stack = createStackNavigator();
var stateNavigator;
var SNavigation = /** @class */ (function (_super) {
    __extends(SNavigation, _super);
    function SNavigation(props) {
        var _this = _super.call(this, props) || this;
        _this.handleDeepLink = function (event) {
            // let data = Linking.parse(event.url);
            console.log("ENTRO EN EL HANDLE DEEPLINK", event);
            if (event.url) {
                openURL(event.url, _this.props.linking.prefixes, false);
                // Navega a la pantalla de detalles con los parámetros del enlace
                // navigation.navigate('Details', { pk: data.queryParams.pk });
            }
        };
        _this.state = {};
        SNavigation.navBar = props.props.navBar;
        SNavigation.INSTANCE = _this;
        return _this;
    }
    SNavigation.reset = function (route, params) {
        if (!SNavigation.lastRoute) {
            alert("no hay navegacion");
            return;
        }
        // if (Platform.OS === "web") {
        //     window.history.pushState([], "", route);
        // }
        // SNavigation.navigation.dispatch(CommonActions.reset({
        //     index: 0,
        //     routes: [{ name: route, params: params }]
        // }))
        // SNavigation.navigation.dispatch(
        //     StackActions.reset({
        //         index: 0,
        //         actions: [NavigationActions.navigate({ routeName: 'Home' })],
        //     })
        // );
        // SNavigation.navigation.
        // SNavigation.lastRoute.navigation.popToTop();
        SNavigation.navigation.reset({
            routes: [{ name: route, params: params }]
        });
        // SNavigation.navigation.dispatch(CommonActions.reset({
        //     index: 0,
        //     routes: [{ name: route, params: params }]
        // }))
        // SNavigation.lastRoute.navigation.reset({
        //     index: 0,
        //     routes: [{ name: route, params: params }]
        // });
    };
    SNavigation.openDeepLink = function (route, replace) {
        if (!SNavigation.INSTANCE)
            return;
        SNavigation.INSTANCE.openDeepLink(route, replace);
    };
    SNavigation.openURL = function (route) {
        Linking.openURL(route);
    };
    SNavigation.navigate = function (route, params) {
        if (!SNavigation.lastRoute) {
            alert("no hay navegacion");
            return;
        }
        // if (Platform.OS === "web") {
        //     window.location.pathname = route
        // }
        SNavigation.lastRoute.navigation.navigate(route, params);
    };
    SNavigation.replace = function (route, params) {
        // if (Platform.OS === "web") {
        //     window.location.pathname = route
        // }
        SNavigation.lastRoute.navigation.replace(route, params);
    };
    SNavigation.goBack = function () {
        if (SNavigation.lastRoute) {
            if (!SNavigation.lastRoute.navigation.canGoBack()) {
                if (SNavigation.lastRoute.route.name == SNavigation.root) {
                    SNavigation.lastRoute.navigation.replace(SNavigation.root);
                }
                if (Platform.OS == "web") {
                    var locstr = window.location.pathname;
                    locstr = locstr.substring(1, locstr.lastIndexOf("/"));
                    if (locstr == "/") {
                        SNavigation.lastRoute.navigation.replace(SNavigation.root);
                    }
                    // SNavigation.lastRoute.navigation.replace(locstr);
                    try {
                        SNavigation.lastRoute.navigation.replace("/" + locstr, SNavigation.getAllParams());
                    }
                    catch (e) {
                        window.location.pathname = locstr;
                    }
                    return;
                }
                else {
                    SNavigation.lastRoute.navigation.replace(SNavigation.root);
                    return;
                }
                // if (locstr.split("/").length <= 2) {
                //     return <View />
                // }
            }
            SNavigation.lastRoute.navigation.goBack();
        }
    };
    SNavigation.getAllParams = function () {
        var _a;
        var route = (_a = SNavigation.lastRoute) === null || _a === void 0 ? void 0 : _a.route;
        var params = route === null || route === void 0 ? void 0 : route.params;
        if (!params) {
            return {};
        }
        return params;
    };
    SNavigation.getParam = function (key, valDef) {
        var _a;
        var route = (_a = SNavigation.lastRoute) === null || _a === void 0 ? void 0 : _a.route;
        var params = route === null || route === void 0 ? void 0 : route.params;
        if (!params) {
            return valDef;
        }
        var param = params[key];
        if (!param) {
            return valDef;
        }
        return param;
    };
    SNavigation.isBack = function () {
        if (Platform.OS == "web") {
            var prevent = false;
            if (SNavigation.lastRoute) {
                if (!SNavigation.lastRoute.navigation.canGoBack()) {
                    if (SNavigation.lastRoute.route.name == SNavigation.root) {
                        return false;
                    }
                    var locstr = window.location.pathname;
                    if (locstr == "/") {
                        return false;
                    }
                }
            }
        }
        else {
            if (!SNavigation.lastRoute) {
                return false;
            }
            if (!SNavigation.lastRoute.navigation.canGoBack()) {
                return false;
            }
        }
        return true;
    };
    SNavigation.prototype.openDeepLink = function (url, replace) {
        openURL(url, this.props.linking.prefixes, replace);
    };
    SNavigation.prototype.componentDidMount = function () {
        this.deleteListener = Linking.addEventListener('url', this.handleDeepLink);
        this.initialURL();
    };
    SNavigation.prototype.initialURL = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Linking.getInitialURL()];
                    case 1:
                        url = _a.sent();
                        console.log("ENTRO AL LINKING GET INITIAL URL", url);
                        if (url != null) {
                            new SThread(1000, "deeplink", false).start(function () {
                                _this.openDeepLink(url);
                            });
                        }
                        if (this.props.linking.getInitialURL)
                            this.props.linking.getInitialURL();
                        return [2 /*return*/];
                }
            });
        });
    };
    SNavigation.prototype.componentWillUnmount = function () {
        if (this.deleteListener) {
            console.log(this.deleteListener);
            this.deleteListener.remove();
            this.deleteListener = null;
        }
    };
    SNavigation.prototype.getPages = function (pages, Stack) {
        var _this = this;
        var _a, _b;
        var Validator = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.validator;
        var currentPage = "";
        return Object.keys(pages).map(function (key) {
            var _a, _b;
            if (!SNavigation.root) {
                SNavigation.root = key;
            }
            var Page = function (props) {
                try {
                    if (props) {
                        SNavigation.lastRoute = props;
                    }
                    var _Page = pages[key].component;
                    if (!_Page) {
                        _Page = pages[key];
                    }
                    return React.createElement(React.Fragment, null,
                        !Validator ? null : React.createElement(Validator, null),
                        _Page.TOPBAR,
                        React.createElement(_Page, __assign({}, props)),
                        _Page.FOOTER);
                }
                catch (e) {
                    console.error(e);
                    return null;
                }
            };
            return React.createElement(Stack.Screen, { key: key, name: key, component: Page, options: __assign({ title: ((_a = _this.props.props) === null || _a === void 0 ? void 0 : _a.title) ? (_b = _this.props.props) === null || _b === void 0 ? void 0 : _b.title : "Servisofts", headerShown: false }, pages[key].options) });
        });
    };
    SNavigation.prototype.render = function () {
        var _a;
        var colors = {
            primary: STheme.color.primary,
            background: STheme.color.background,
            card: STheme.color.card,
            text: STheme.color.text,
            border: "",
            notification: STheme.color.primary
        };
        var pages = __assign(__assign({}, this.props.props.pages), Pages);
        return (React.createElement(NavigationContainer, { ref: function (ref) {
                SNavigation.navigation = ref;
            }, linking: SLinking((_a = this.props.linking) !== null && _a !== void 0 ? _a : {}, pages), 
            // onReady={() => {
            //     if (this?.props?.linking?.getInitialURL) {
            //         this.props.linking.getInitialURL();
            //     }
            // }}
            theme: { dark: false, colors: colors }, onStateChange: function (state) {
                return stateNavigator = state;
            } },
            React.createElement(Stack.Navigator, null, this.getPages(pages, Stack))));
    };
    SNavigation.navigation = null;
    SNavigation.navBar = null;
    SNavigation.routes = [];
    return SNavigation;
}(Component));
export default SNavigation;
