var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import Table from './Table';
import SView from '../SView';
import Headers from './Headers';
import RowNumbers from './RowNumbers';
import Scroll from './Scroll';
import ToolBar from './ToolBar';
import ExcelExport from './ExcelExport';
var STable3 = /** @class */ (function (_super) {
    __extends(STable3, _super);
    function STable3() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            rows: new Array(15000).fill(_this.props.rowHeight),
            cols: new Array(20).fill(_this.props.colWidth)
        };
        _this.contentWidth = 0;
        _this.contentHeight = 0;
        _this.scrollEventThrottle = 1000 / 40;
        return _this;
    }
    STable3.prototype.render = function () {
        var _this = this;
        this.contentWidth = 0;
        this.contentHeight = 0;
        this.state.cols.map(function (v) { return _this.contentWidth += v; });
        this.state.rows.map(function (v) { return _this.contentHeight += v; });
        return React.createElement(SView, { col: "xs-12", flex: true },
            React.createElement(ToolBar, null),
            React.createElement(SView, { col: "xs-12", row: true, height: 25 },
                React.createElement(SView, { width: 40, height: true, style: {
                        borderBottomWidth: 1,
                        borderRightWidth: 1,
                        borderColor: "#666666"
                    } },
                    React.createElement(ExcelExport, { parent: this })),
                React.createElement(Headers, { ref: function (ref) { return _this.headers = ref; }, parent: this }),
                React.createElement(SView, { width: 15, height: true })),
            React.createElement(SView, { col: "xs-12", row: true, flex: true },
                React.createElement(RowNumbers, { ref: function (ref) { return _this.rowNumbers = ref; }, parent: this }),
                React.createElement(SView, { flex: true, height: true },
                    React.createElement(Table, { ref: function (ref) { return _this.table = ref; }, parent: this, data: this.props.data })),
                React.createElement(Scroll, { ref: function (ref) { return _this.scroll = ref; }, parent: this })));
    };
    STable3.defaultProps = {
        data: [],
        colWidth: 150,
        rowHeight: 20
    };
    return STable3;
}(Component));
export default STable3;
