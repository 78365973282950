var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _this = this;
import React from "react";
import { SDate, SText, STheme, SView } from "../../";
var SemanasDelMes = ["Primera", "Segunda", "Tercera", "Cuarta", "Quinta", "Secta"];
var DiasDeLaSemana = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];
var config = {
    fontScale: 18
};
var Colors = {
    border: "#66666666",
    text: STheme.color.text,
    textGray: "#666"
};
var Dia = function (_a) {
    var y = _a.y, x = _a.x, date = _a.date, state = _a.state, onSelect = _a.onSelect;
    var isCurMonth = date.getMonth() == state.mes;
    var isToday = date.isCurDate();
    return React.createElement(SView, { flex: true, style: {
            borderColor: Colors.border,
            borderWidth: 1,
            alignItems: "flex-end"
        }, onPress: !onSelect ? null : onSelect.bind(_this, date) },
        React.createElement(SView, { style: {
                width: config.fontScale * 2,
                height: config.fontScale * 2,
                backgroundColor: isToday ? "#f00" : "",
                borderRadius: config.fontScale * 2
            }, center: true },
            React.createElement(SText, { fontSize: config.fontScale, color: isCurMonth ? Colors.text : Colors.textGray }, "".concat(date.getDay()))));
};
var Semana = function (_a) {
    var y = _a.y, firstDayOfWeek = _a.firstDayOfWeek, state = _a.state, onSelect = _a.onSelect;
    var date = firstDayOfWeek.clone();
    date.addDay(-1);
    return React.createElement(SView, { col: "xs-12", row: true, flex: true }, DiasDeLaSemana.map(function (a, i) {
        date.addDay(1);
        return React.createElement(Dia, { y: y, x: i, date: date.clone(), state: state, onSelect: onSelect });
    }));
};
var Header = function (_a) {
    return React.createElement(SView, { col: "xs-12", row: true }, DiasDeLaSemana.map(function (a, i) { return React.createElement(SView, { flex: true, style: {
            alignItems: "flex-end",
            padding: 4
        } },
        React.createElement(SText, { fontSize: config.fontScale }, a)); }));
};
var DiasDelMes = /** @class */ (function (_super) {
    __extends(DiasDelMes, _super);
    function DiasDelMes() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            ano: new SDate().getYear(),
            mes: new SDate().getMonth()
        };
        return _this;
    }
    DiasDelMes.prototype.render = function () {
        var _this = this;
        var date = new SDate(this.state.ano + "-01-01", "yyyy-MM-dd");
        date.addMonth(this.state.mes - 1);
        var curdate = date.clone();
        date = date.getFirstDayOfWeek();
        date.addDay(-7);
        return React.createElement(SView, { flex: true },
            React.createElement(SView, { height: "100%" },
                React.createElement(SView, { row: true },
                    React.createElement(SText, { fontSize: config.fontScale * 1.8, bold: true }, curdate.toString("MONTH yyyy")),
                    React.createElement(SView, { flex: true }),
                    React.createElement(SView, { row: true },
                        React.createElement(SText, { width: 50, card: true, center: true, fontSize: config.fontScale * 1.8, onPress: function () {
                                curdate.addMonth(-1);
                                _this.setState({ mes: curdate.getMonth(), ano: curdate.getYear() });
                            } }, " < "),
                        React.createElement(SView, { width: 4 }),
                        React.createElement(SText, { center: true, card: true, fontSize: config.fontScale * 1.4, onPress: function () {
                                curdate = new SDate();
                                _this.setState({ mes: curdate.getMonth(), ano: curdate.getYear() });
                            } }, " Hoy "),
                        React.createElement(SView, { width: 4 }),
                        React.createElement(SText, { width: 50, card: true, center: true, fontSize: config.fontScale * 1.8, onPress: function () {
                                curdate.addMonth(1);
                                _this.setState({ mes: curdate.getMonth(), ano: curdate.getYear() });
                            } }, " > "))),
                React.createElement(Header, null),
                SemanasDelMes.map(function (a, i) {
                    date.addDay(7);
                    return React.createElement(Semana, { y: i, firstDayOfWeek: date.getFirstDayOfWeek().clone(), state: _this.state, onSelect: _this.props.onSelect });
                })));
    };
    return DiasDelMes;
}(React.Component));
export default DiasDelMes;
