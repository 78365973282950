export default new /** @class */ (function () {
    function SGeolocation() {
    }
    SGeolocation.prototype.getCurrentPosition = function (opt) {
        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(function (e) {
                console.log("success", e);
                resolve(e);
            }, function (e) {
                console.log("error", e);
                reject(e);
            });
        });
    };
    return SGeolocation;
}())();
