var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import SView from '../SView';
import Sheet from './Sheet';
var STable3 = /** @class */ (function (_super) {
    __extends(STable3, _super);
    function STable3() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        return _this;
    }
    STable3.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        // Sólo vuelva a renderizar si la prop 'count' ha cambiado
        return this.props.data !== nextProps.data;
    };
    STable3.prototype.loadData = function () {
    };
    STable3.prototype.render = function () {
        return React.createElement(SView, { col: "xs-12", flex: true, backgroundColor: this.props.colorBackground },
            React.createElement(Sheet, { table: this, data: this.props.data }));
    };
    STable3.defaultProps = {
        colorBackground: "#ffffff",
        colorText: "#000000",
        borderColor: "#D0D0D0",
        colorMenu: "#ECECEC"
    };
    return STable3;
}(Component));
export default STable3;
