export default {
    debug: true, //para que se vea los returns del server
    name: 'dhm',

    ssl: true,
    host: 'dhm.servisofts.com',
    
    // ssl: false,
    // host: '192.168.2.1',
    port: {
        native: 10049,
        web: 20049,
        http: 30049
    },
    // cert: 'MIID0DCCArigAwIBAgIEYgRJTTANBgkqhkiG9w0BAQsFADCBqTELMAkGA1UEBhMCQk8xEjAQBgNVBAgMCUF2IEJhbnplcjETMBEGA1UEBwwKU2FudGEgQ3J1ejEXMBUGA1UECgwOU2Vydmlzb2Z0cyBTUkwxDzANBgNVBAsMBnRhcGVrZTEeMBwGA1UEAwwVdGFwZWtlLnNlcnZpc29mdHMuY29tMScwJQYJKoZIhvcNAQkBFhhyaWNreS5wYXouZC45N0BnbWFpbC5jb20wHhcNMjIwMjA5MjMwNzU3WhcNMjIwMjEwMjMwNzU3WjCBqTELMAkGA1UEBhMCQk8xEjAQBgNVBAgMCUF2IEJhbnplcjETMBEGA1UEBwwKU2FudGEgQ3J1ejEXMBUGA1UECgwOU2Vydmlzb2Z0cyBTUkwxDzANBgNVBAsMBnRhcGVrZTEeMBwGA1UEAwwVdGFwZWtlLnNlcnZpc29mdHMuY29tMScwJQYJKoZIhvcNAQkBFhhyaWNreS5wYXouZC45N0BnbWFpbC5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQCaIRwLARQqohcmnm44B3Qca60sKgo/H6tXeQ7tQyvrMAmNZ2mcod0xDpUFnyEu9Ua+Bb9kF1PCPB0udqLmKG5ZeXoHkSRNF/yM++IxzjUDjNw13hyh/2cfi7GvEC2gUer5TBl0ZJWVGGcXUywlFo5DtH2uAbO8QKnnpqYEqAH9fbiP9P7TqbpkbEcMz8bgsDc8+RJB5nqcc/jdUCRA9n6a1ey3ncH26qtInsp/m0reF+MTJBzMJbYU9erUAP/GKcwmy4NE2UiFwu0QDF6AjuaI3m3FJ5Gp2z59aJeaXdHAx2SiB0wsZuw6Xun29RcGZ2KQk6iIkKg+xFU9E7AnI33BAgMBAAEwDQYJKoZIhvcNAQELBQADggEBAGASGpT2VF+qrPj8cRwMIttnsxT/eOKWjG6gL3RewnBen7bTmAg4qjZ4TYLte6aLbbu6V/WDh52WN5rarLj3/KOHfcKmsKHpjfCuapcGEisRvB9Hpx9sePqxsEm0wjjpyfArX+Yd2E6/hFeeDnWitjgtMe5NvvSDdnVOaUliZPzbMlUT+SB60zZC00PhNCJIr+uKdApVRBabMUMNlZoM5O2fiJ7KlpKnkZWh4Xsgs2cb97pbOYfXo5NU9C+F2tjoAOkBUZVXduKTnYeojvWgyK1YNDzOeX4F3gAXpwkXdH38NtuIpSKDsMmfWRk+YU931YYAck6aBhTSEGvRe3OdQHM=',
    cert: 'MIIDyjCCArKgAwIBAgIEZJ5fYzANBgkqhkiG9w0BAQsFADCBpjELMAkGA1UEBhMCQk8xEjAQBgNVBAgMCUF2IEJhbnplcjETMBEGA1UEBwwKU2FudGEgQ3J1ejEaMBgGA1UECgwRU0VSVklTT0ZUUyBTLlIuTC4xDDAKBgNVBAsMA2RobTEbMBkGA1UEAwwSZGhtLnNlcnZpc29mdHMuY29tMScwJQYJKoZIhvcNAQkBFhhzZXJ2aXNvZnRzLnNybEBnbWFpbC5jb20wHhcNMjMwNjMwMDQ1MTQ3WhcNMjMwNzAxMDQ1MTQ4WjCBpjELMAkGA1UEBhMCQk8xEjAQBgNVBAgMCUF2IEJhbnplcjETMBEGA1UEBwwKU2FudGEgQ3J1ejEaMBgGA1UECgwRU0VSVklTT0ZUUyBTLlIuTC4xDDAKBgNVBAsMA2RobTEbMBkGA1UEAwwSZGhtLnNlcnZpc29mdHMuY29tMScwJQYJKoZIhvcNAQkBFhhzZXJ2aXNvZnRzLnNybEBnbWFpbC5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQCepVuhMpXWSNzo241LN2yVA+k+m7e/ZMvKEmHyY8bpSJQOAOiT9ZEpbb2ujy3y6VRT+evSvpD1G01ie6oV1hvB2CfdVsq3iYdGa1G6EZcNL5Hl+rgEEn7HMLmd5yJtgB24I9Gl9FuI4vW4KpfqeV/77zFzTxc8mycyKpQpQmJzCEWzhHWB78T3uFPJ6nhiL4f3tvR7hnHMwqMatNj/KxLVGUUG2SDuCsctADVBoQ6sa9P5rUNm71sZWsUEdKvNFAQhBvlYOapNmqUnpUbubY0/Q5nVgtzyXqJ5kJd+BxAba6ru+WiK+3KE5Zq8kffHcYYXfvU7xp8u/Zq73g/Q4pvJAgMBAAEwDQYJKoZIhvcNAQELBQADggEBADDwbNFfn1ce8x9qySEYaQQ/qhDXmckgI+i3yz5HzNCCH+4/a/ASMhl1N84hXKLVRs2nfukVsVa/cKGuT+eEAN48NO3bts3I6IpGImt8fcyJOSFJYPDaE7xbXcdYit5ztOzTw9zZ7fPeRLiGOg95zEG5WhyQAKKfIsRowxvM08HXXMMLyQWF8xFB5ZYIyG5lD6Lsmi3iyzvkR0L4V9GTDFrzWkPxvsdFKDvTaC29j18+qN2lA6OnD1JyA8Y0bsi2w6mF4p8JPkB8qMLk3lWBGpnJs+DG78v2oEX0AgAyHxzLqNaXg2QCQ3HISwYhp2spRnHoU27ql4Imgsg7fuw9trs=',
    apis: {
        roles_permisos: 'https://rolespermisos.servisofts.com/http/',
        empresa: 'https://empresa.servisofts.com/http/',
        inventario: 'https://inventario.servisofts.com/http/',
    },
    timeReconnect: 5000
}