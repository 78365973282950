import BilleteraSinFondos from "./types/BilleteraSinFondos"
import TapekesAgotados from "./types/TapekesAgotados"
import InicieSession from "./types/InicieSession"
import TapekeComprado from "./types/TapekeComprado"
import AgregarUbicacion from "./types/AgregarUbicacion"
export default {
    BilleteraSinFondos,
    TapekesAgotados,
    InicieSession,
    TapekeComprado,
    AgregarUbicacion
}