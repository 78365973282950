var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Text, View } from 'react-native';
import React, { Component } from 'react';
var cell = /** @class */ (function (_super) {
    __extends(cell, _super);
    function cell() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    cell.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        // Sólo vuelva a renderizar si la prop 'count' ha cambiado
        return this.props.children !== nextProps.children || this.props.width !== nextProps.width;
    };
    cell.prototype.render = function () {
        // onPress={()=>{
        //     this.props.sheet.setState({select:this.props.code})
        // }}
        return React.createElement(View, { style: {
                width: this.props.width,
                height: this.props.height,
                borderRightWidth: 1,
                borderBottomWidth: 1,
                borderColor: this.props.borderColor,
                padding: 4,
                justifyContent: "center"
            } },
            React.createElement(Text, { style: { fontSize: 11 } }, this.props.children));
    };
    return cell;
}(Component));
export default cell;
