var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ScrollView, View, FlatList } from 'react-native';
import React, { Component } from 'react';
import SView from '../SView';
import SText from '../SText';
import Cell from './cell';
import ToolBar from "./ToolBar";
import SResizableView from '../SResizableView';
var Sheet = /** @class */ (function (_super) {
    __extends(Sheet, _super);
    function Sheet() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            scrollSize: 15,
            col: new Array(10).fill(1),
            row: new Array(1000).fill(1),
            // row: new Array(this.props?.data?.length ?? 50).fill(1),
            select: "A1",
            "!cols": {
            // "A": { wpx: 200 },
            // "B": { wpx: 200 },
            // "E": { wpx: 200 },
            }
        };
        _this.headerLetter = function (_a) {
            var width = _a.width, val = _a.val;
            if (_this.state['!cols'][val]) {
                var constProps = _this.state['!cols'][val];
                if (constProps.wpx) {
                    width = constProps.wpx;
                }
            }
            return React.createElement(SResizableView, { width: width, onContentSizeChange: function (e) {
                    _this.state['!cols'][val] = {
                        wpx: e
                    };
                    _this.setState(__assign({}, _this.state));
                    // console.log(val, e);
                } },
                React.createElement(View, { style: {
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRightWidth: 1,
                        borderBottomWidth: 1,
                        borderColor: _this.props.table.props.borderColor
                    } },
                    React.createElement(SText, { color: _this.props.table.props.colorText }, val)));
        };
        _this.headerNumber = function (_a) {
            var _b = _a.height, height = _b === void 0 ? null : _b, val = _a.val;
            return React.createElement(View, { style: {
                    width: "100%",
                    height: height,
                    alignItems: "center",
                    justifyContent: "center",
                    borderBottomWidth: 1,
                    borderRightWidth: 1,
                    borderColor: _this.props.table.props.borderColor
                } },
                React.createElement(SText, { color: _this.props.table.props.colorText }, val));
        };
        return _this;
    }
    Sheet.prototype.convertirNumeroALetra = function (numero) {
        var res = String.fromCharCode(64 + ((numero - 1) % 26) + 1);
        if (numero > 26) {
            res = String.fromCharCode(64 + Math.floor((numero - 1) / 26)) + res;
        }
        "".length;
        return res;
    };
    Sheet.prototype.renderData = function () {
        var _this = this;
        return React.createElement(FlatList, { data: this.state.row, keyExtractor: function (a, i) { return i + ""; }, 
            // scrollEnabled={false}
            // getItemLayout={(data, index) => ({ length: 50, offset: 10 * index, index })}
            initialNumToRender: 20, maxToRenderPerBatch: 20, windowSize: 10, renderItem: function (row) {
                var _a, _b, _c, _d;
                var arrDataRow = [];
                if (row.index == 0) {
                    // arrDataRow = Object.keys(this.props?.data[row.index])
                }
                else {
                    var indexR = row.index - 1;
                    if ((_a = _this.props) === null || _a === void 0 ? void 0 : _a.data[indexR]) {
                        if (typeof ((_b = _this.props) === null || _b === void 0 ? void 0 : _b.data[indexR]) == "object") {
                            arrDataRow = Object.values((_c = _this.props) === null || _c === void 0 ? void 0 : _c.data[indexR]);
                        }
                        else {
                            arrDataRow = (_d = _this.props) === null || _d === void 0 ? void 0 : _d.data[indexR];
                        }
                    }
                }
                return React.createElement(FlatList, { horizontal: true, data: _this.state.col, 
                    // scrollEnabled={false}
                    keyExtractor: function (a, i) { return row.index + i + ""; }, renderItem: function (col) {
                        var letter = _this.convertirNumeroALetra(col.index + 1);
                        var content = "";
                        if (arrDataRow[col.index]) {
                            content = arrDataRow[col.index];
                        }
                        var width = 100;
                        if (_this.state['!cols'][letter]) {
                            var constProps = _this.state['!cols'][letter];
                            if (constProps.wpx) {
                                width = constProps.wpx;
                            }
                        }
                        return React.createElement(Cell, { sheet: _this, code: letter + (row.index + 1), height: 20, width: width, borderColor: _this.props.table.props.borderColor }, content);
                        // >{letter + (row.index + 1)}</Cell>
                    } });
            } });
    };
    Sheet.prototype.render = function () {
        var _this = this;
        return React.createElement(SView, { col: "xs-12", flex: true, backgroundColor: this.props.table.props.colorMenu },
            React.createElement(ToolBar, { sheet: this }),
            React.createElement(SView, { col: "xs-12", flex: true },
                React.createElement(SView, { col: "xs-12", height: 25, row: true },
                    React.createElement(View, { style: {
                            width: 30, height: "100%", borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderColor: this.props.table.props.borderColor
                        } }),
                    React.createElement(ScrollView, { ref: function (ref) { return _this.scroll_header_h = ref; }, horizontal: true, style: { flex: 1 }, showsHorizontalScrollIndicator: false, 
                        // scrollEnabled={false}
                        scrollEventThrottle: 16 },
                        React.createElement(FlatList, { data: this.state.col, horizontal: true, scrollEnabled: false, renderItem: function (col) { return _this.headerLetter({ val: _this.convertirNumeroALetra(col.index + 1), width: 100 }); } })),
                    React.createElement(SView, { width: this.state.scrollSize, height: true })),
                React.createElement(SView, { col: "xs-12", flex: true, row: true },
                    React.createElement(SView, { width: 30, height: true },
                        React.createElement(ScrollView, { ref: function (ref) { return _this.scroll_header_v = ref; }, style: { flex: 1 }, showsVerticalScrollIndicator: false, scrollEnabled: false },
                            React.createElement(FlatList, { data: this.state.row, initialNumToRender: 20, maxToRenderPerBatch: 20, windowSize: 10, scrollEnabled: false, renderItem: function (row) { return _this.headerNumber({ val: row.index + 1, height: 20 }); } }))),
                    React.createElement(SView, { flex: true, height: true, row: true, backgroundColor: this.props.table.props.colorBackground },
                        React.createElement(ScrollView, { style: {
                                height: "100%"
                            }, showsVerticalScrollIndicator: false, scrollEventThrottle: 16, bounces: false, onScroll: function (e) { return _this.scroll_header_v.scrollTo(__assign(__assign({}, e.nativeEvent.contentOffset), { animated: false })); } },
                            React.createElement(ScrollView, { style: {
                                    width: "100%"
                                }, showsHorizontalScrollIndicator: false, horizontal: true, scrollEventThrottle: 16, bounces: false, onScroll: function (e) { return _this.scroll_header_h.scrollTo(__assign(__assign({}, e.nativeEvent.contentOffset), { animated: false })); } }, this.renderData()))),
                    React.createElement(SView, { width: this.state.scrollSize, height: true })),
                React.createElement(SView, { width: "100%", height: this.state.scrollSize })));
    };
    return Sheet;
}(Component));
export default Sheet;
