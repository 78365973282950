import Card from "./Card"
import Card2 from "./Card2"
import CardAntigua from "./CardAntigua"
import Cantidad from "./Cantidad"
export default {
    // "Card": CardAntigua,
    Card,
    // "Card3": Card,
    Card2,
    Cantidad
}