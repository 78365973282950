var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import SView from '../SView';
import STheme from '../STheme';
import SLoad from '../SLoad';
var SSwitch = /** @class */ (function (_super) {
    __extends(SSwitch, _super);
    function SSwitch(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { value: _this.props.defaultValue };
        return _this;
    }
    SSwitch.prototype.setValue = function (val) {
        if (this.props.loading)
            return;
        if (this.props.onChange) {
            this.props.onChange(val);
        }
        this.setState({ value: val });
    };
    SSwitch.prototype["switch"] = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        if (this.props.value) {
            this.state.value = this.props.value;
        }
        else if (this.props.value === false) {
            this.state.value = this.props.value;
        }
        var size = (_a = this.props.size) !== null && _a !== void 0 ? _a : 20;
        var width = size * ((_b = this.props.scale) !== null && _b !== void 0 ? _b : 2);
        var color = (_c = this.props.color) !== null && _c !== void 0 ? _c : STheme.color.text;
        var colorActive = (_d = this.props.colorActive) !== null && _d !== void 0 ? _d : color;
        var backgroundColor = (_e = this.props.backgroundColor) !== null && _e !== void 0 ? _e : STheme.color.lightGray;
        var backgroundColorActive = (_f = this.props.backgroundColorActive) !== null && _f !== void 0 ? _f : STheme.color.success;
        var space = 3;
        return React.createElement(SView, { center: true, width: width, height: size, onPress: function () {
                _this.setValue(!_this.state.value);
            } },
            React.createElement(SView, { col: "xs-12", height: size * ((_g = this.props.scaleBar) !== null && _g !== void 0 ? _g : 1), style: {
                    borderRadius: 100,
                    // borderWidth: 2,
                    // borderColor: !this.state.value ? STheme.color.card : STheme.color.success,
                    backgroundColor: !this.state.value ? backgroundColor : backgroundColorActive
                } }),
            React.createElement(SView, { style: __assign({ position: "absolute", width: size - space, height: size - space, borderRadius: 100, backgroundColor: !this.state.value ? color : colorActive }, (!this.state.value ? { left: space / 2 } : { right: space / 2 })), center: true }, this.props.loading ? React.createElement(SLoad, { color: STheme.color.primary }) : null));
    };
    SSwitch.prototype.render = function () {
        return this["switch"]();
    };
    return SSwitch;
}(Component));
export default SSwitch;
