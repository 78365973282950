import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import DeviceKey from "./DeviceKey";
import { SNotification } from "servisofts-component";
// export { DeviceKey };
class Firebase {
    static async getInitialURL() {

    }
    static async init() {
        // DeviceKey.init();
        try {

            const firebaseConfig = {
                apiKey: "AIzaSyArzc5I4Rh1FXxCrocjpEc2ahU1guKFo_E",
                authDomain: "dhm-distribuidora.firebaseapp.com",
                projectId: "dhm-distribuidora",
                storageBucket: "dhm-distribuidora.appspot.com",
                messagingSenderId: "960533447267",
                appId: "1:960533447267:web:cb68493ce059990567ee56"
            };
            const app = initializeApp(firebaseConfig);
            const messaging = getMessaging(app);
            getToken(messaging, { vapidKey: "BDiCRYTU5S8vsOGxuSrC_pZd8mfc2x_C2HpOXX7MYzSMwjDSdAtQi23_vvS5LUjYG6IJ_uKpYWPA9MxLsl27XzY" }).then((currentToken) => {
                if (currentToken) {
                    console.log('Token: ', currentToken);
                    DeviceKey.setKey(currentToken);
                    // Send the token to your server and update the UI if necessary
                    // ...
                    onMessage(messaging, (payload) => {
                        console.log('Message received. ', payload);
                        Notification.requestPermission().then(function (result) {
                            var notification = new Notification(payload.notification.title, { body: payload.notification.body });
                        });
                        SNotification.send(payload.notification)
                        // ...
                    });
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });
        } catch (err) {
            console.log("error", err);
        }

    }
}
export default Firebase;