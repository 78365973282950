var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import SView from '../SView';
var ToolBar = /** @class */ (function (_super) {
    __extends(ToolBar, _super);
    function ToolBar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ToolBar.prototype.render = function () {
        // const table = this.props.sheet.props.table;
        // const sheet = this.props.sheet;
        return React.createElement(SView, { col: "xs-12", height: this.props.height, padding: 4 },
            React.createElement(SView, { col: "xs-12", row: true, height: true },
                React.createElement(SView, { width: 100, border: 1, height: true, style: { borderColor: "#666666" } }),
                React.createElement(SView, { width: 4 }),
                React.createElement(SView, { width: 80, border: 1, height: true, style: { borderColor: "#666666" } }),
                React.createElement(SView, { width: 4 }),
                React.createElement(SView, { flex: true, border: 1, style: { borderColor: "#666666" } })));
    };
    ToolBar.defaultProps = {
        borderColor: "#6666",
        backgroundColor: "#ffffff",
        height: 30
    };
    return ToolBar;
}(Component));
export default ToolBar;
