import circle from "./type/circle";
import window from "./type/window";
import skeleton from "./type/skeleton";
import bar from "./type/bar";
export default {
    circle: circle,
    window: window,
    skeleton: skeleton,
    bar: bar
};
