var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { PureComponent } from 'react';
import { Animated, Easing } from 'react-native';
import { STheme, SView } from '../../../';
var BarraCargando = /** @class */ (function (_super) {
    __extends(BarraCargando, _super);
    function BarraCargando(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
        // size: 250,
        // dotSize: 16
        };
        _this.animValue = new Animated.Value(0);
        return _this;
    }
    BarraCargando.prototype.componentDidMount = function () {
        this.anim_rotar();
    };
    BarraCargando.prototype.anim_rotar = function () {
        var _this = this;
        Animated.timing(this.animValue, {
            toValue: 1,
            duration: 3500 * (Math.random() * 0.2 + 0.5),
            useNativeDriver: true,
            easing: Easing.linear
        }).start(function () {
            _this.animValue.setValue(0);
            _this.anim_rotar();
        });
    };
    BarraCargando.prototype.getBar = function () {
        if (!this.state.size)
            return null;
        return React.createElement(SView, { animated: true, style: {
                left: 1,
                transform: [{
                        translateX: this.animValue.interpolate({ inputRange: [0, 1], outputRange: [-50, this.state.size + 50] })
                    }]
            } },
            React.createElement(SView, __assign({ width: 50, height: 4, style: { borderRadius: 8, backgroundColor: this.props.color } }, this.props)));
    };
    BarraCargando.prototype.render = function () {
        var _this = this;
        return (React.createElement(SView, __assign({ col: "xs-12", height: 6, style: { justifyContent: 'center', overflow: 'hidden', backgroundColor: STheme.color.card, borderRadius: 8 } }, this.props, { onLayout: function (event) {
                _this.setState({ size: event.nativeEvent.layout.width });
            } }), this.getBar()));
    };
    return BarraCargando;
}(PureComponent));
export default BarraCargando;
