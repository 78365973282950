import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SPage } from 'servisofts-component'

export default class cliente extends Component {
    render() {
        return <SPage>

        </SPage>
    }
}