import React from 'react';
import { SHr, SIcon, SImage, SLoad, SText, STheme, SView } from '../../';
import SNotification from '.';
var CloseIcon = function (_a) {
    var data = _a.data;
    return React.createElement(SView, { style: {
            position: "absolute",
            top: 0,
            right: 0,
            width: 25,
            height: 25,
            padding: 0,
            alignItems: "flex-end"
        }, onPress: function () {
            var _a;
            SNotification.remove((_a = data.key) !== null && _a !== void 0 ? _a : "");
        } },
        React.createElement(SIcon, { width: 8, height: 8, fill: STheme.color.gray, name: 'Cerrar' }));
};
var NotificationItem = function (props) {
    var _a, _b, _c, _d, _e;
    var data = props.data;
    return (React.createElement(SView, { key: props.data.key, onPress: props.onPress, activeOpacity: 0, style: {
            width: 220,
            // top: 8 + (60) * props.index,
            borderRadius: 4,
            backgroundColor: STheme.color.background + "EE",
            // overflow: "hidden",
            padding: 4,
            borderWidth: 2,
            borderColor: STheme.color.card,
            borderLeftWidth: 4,
            borderStartColor: (_b = (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.color) !== null && _b !== void 0 ? _b : STheme.color.lightGray,
            flexDirection: "row",
            alignItems: "center"
        } },
        React.createElement(SView, { flex: true, padding: 4 },
            React.createElement(SText, { font: 'Roboto', style: {
                    fontWeight: "bold"
                } }, (_c = props === null || props === void 0 ? void 0 : props.data) === null || _c === void 0 ? void 0 : _c.title),
            React.createElement(SHr, { h: 4 }),
            React.createElement(SText, { font: 'Roboto', style: {
                    color: STheme.color.gray,
                    fontSize: 12
                } }, (_d = props === null || props === void 0 ? void 0 : props.data) === null || _d === void 0 ? void 0 : _d.body),
            data.type == "loading" ? React.createElement(SLoad, { type: 'bar' }) : null),
        !data.image ? null : React.createElement(SView, { width: 35, height: 35, card: true, style: { overflow: "hidden", borderRadius: 4 } },
            React.createElement(SImage, { src: (_e = props === null || props === void 0 ? void 0 : props.data) === null || _e === void 0 ? void 0 : _e.image }))));
};
export default NotificationItem;
