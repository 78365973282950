import { SModel } from "servisofts-model";
import Action from "./Action";
import Reducer from "./Reducer";

export default new SModel<Action, Reducer>({
    info: {
        component: "tbprd"
    },
    Columns: {
        "idprd": { type: "integer", pk: true },
        "prdcod": { type: "text",  editable: true },
        "prdnom": { type: "text",  editable: true },
        "prdunid": { type: "text",  editable: true },
        "prdcxu": { type: "double",  editable: true },
        "idlinea": { type: "integer",  editable: true },
        "prdstmax": { type: "double",  editable: true },
        "prdstmin": { type: "double",  editable: true },
        "prdcom": { type: "text",  editable: true },
        "prdpoficial": { type: "double",  editable: true },
        "prdpmaycon": { type: "double",  editable: true },
        "prdpmaycre": { type: "double",  editable: true },
        "prdpmincon": { type: "double",  editable: true },
        "prdpmincre": { type: "double",  editable: true },
        "prdpespcon": { type: "double",  editable: true },
        "prdpespcre": { type: "double",  editable: true },
        "prdpsmcon": { type: "double",  editable: true },
        "prdpsmcre": { type: "double",  editable: true },
        "prdpagcon": { type: "double",  editable: true },
        "prdpagcre": { type: "double",  editable: true },
        "prdmrc": { type: "text",  editable: true },
        "barracod": { type: "text",  editable: true },
        "barratipo": { type: "integer",  editable: true },
        "barramio": { type: "integer",  editable: true },
        "prdice": { type: "double",  editable: true },
        "prddes": { type: "double",  editable: true },
        "prdprec": { type: "double",  editable: true },
        "prdest": { type: "integer",  editable: true },
        "prddesc": { type: "integer",  editable: true },
        "prdcor": { type: "text",  editable: true },
        "prdcms": { type: "double",  editable: true },
        "prdtip": { type: "integer",  editable: true },
        "prdabs": { type: "text",  editable: true },
        "prddst": { type: "text",  editable: true },
        "prdcb1": { type: "text",  editable: true },
        "prdcb2": { type: "text",  editable: true },
        "prdcb3": { type: "text",  editable: true },
        "prdcb4": { type: "text",  editable: true },
        "prdcb5": { type: "text",  editable: true },
        "prdcb6": { type: "text",  editable: true },
        "prdcb7": { type: "text",  editable: true },
        "prdcb8": { type: "text",  editable: true },
        "prdpxv": { type: "text",  editable: true },
        "prdctaing": { type: "integer",  editable: true },
        "prdctacos": { type: "integer",  editable: true },
        "prdcms2": { type: "double",  editable: true },
        "idanp": { type: "integer",  editable: true },
        "prdkxu": { type: "double",  editable: true },
        "prduxcdes": { type: "text",  editable: true },
        "prduxd": { type: "double",  editable: true },
        "prduxddes": { type: "text",  editable: true },
        "prduvdef": { type: "integer",  editable: true },
        "prdcodunilever": { type: "text",  editable: true },
        "prdtvol": { type: "integer",  editable: true },
        "prdklxc": { type: "double",  editable: true },
        "prduniua": { type: "text",  editable: true },
        "prdskxc": { type: "double",  editable: true },
        "idunicat": { type: "integer",  editable: true },
        "prdunides": { type: "text",  editable: true },
        "idpg": { type: "integer",  editable: true },
        "prddim": { type: "text",  editable: true },
        "prdvol": { type: "text",  editable: true },
        "prdmed": { type: "text",  editable: true },
        "prusumod": { type: "text",  editable: true },
        "prfecmod": { type: "date",  editable: true },
        "prdfoto": { type: "text",  editable: true },
        "prdpxgr": { type: "double",  editable: true },
        "prdeqpt": { type: "double",  editable: true },
        "prdcap": { type: "text",  editable: true },
        "prdpoficialUS": { type: "double",  editable: true },
        "prdpmayconUS": { type: "double",  editable: true },
        "prdpmaycreUS": { type: "double",  editable: true },
        "prdpminconUS": { type: "double",  editable: true },
        
        "prdpmincreUS": { type: "double",  editable: true },
        "prdpespconUS": { type: "double",  editable: true },
        "prdpespcreUS": { type: "double",  editable: true },
        "prdpsmconUS": { type: "double",  editable: true },
        "prdpsmcreUS": { type: "double",  editable: true },
        "prdpagconUS": { type: "double",  editable: true },
        "prdpagcreUS": { type: "double",  editable: true },
        "idmarca": { type: "integer",  editable: true },
        "idmotor": { type: "integer",  editable: true },
        "prdmedidarep": { type: "text",  editable: true },
        "prdbal": { type: "integer",  editable: true },
        "prdp6con": { type: "double",  editable: true },
        "prdp6cre": { type: "double",  editable: true },
        "prdp7con": { type: "double",  editable: true },
        "prdp7cre": { type: "double",  editable: true },
        "prdp6conus": { type: "double",  editable: true },
        "prdp6creus": { type: "double",  editable: true },
        "prdp7conus": { type: "double",  editable: true },
        "prdp7creus": { type: "double",  editable: true },
        "prdancho": { type: "double",  editable: true },
        "prdalto": { type: "double",  editable: true },
        "sucreg": { type: "integer",  editable: true },

    },
    image: {
        api: "root",
        name: "tbprd"
    },
    Action,
    Reducer,
});