var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Svg from "react-native-svg";
import { Path } from "react-native-svg";
var Web = function (props) { return (
// <Svg  {...props} viewBox="0 0 74 53.87">
//     <Path d="M78,62.52V49.37a13,13,0,0,0-13-13H17a13,13,0,0,0-13,13V62.52Z" transform="translate(-4 -14.2)" />
//     <Rect x="11.68" y="49.39" width="16.89" height="4.49" />
//     <Rect x="45.43" y="49.39" width="16.89" height="4.49" />
//     <Path d="M65,14.2H17a13,13,0,0,0-13,13V42.32H4c.41-4.49,5.17-8.12,11.27-8.68V30.2a3.82,3.82,0,0,1,3.83-3.83H35.55a3.82,3.82,0,0,1,3.82,3.83v3.36h3.26V30.2a3.82,3.82,0,0,1,3.82-3.83H62.86a3.82,3.82,0,0,1,3.83,3.83v3.44c6.1.56,10.86,4.19,11.27,8.68h0V27.18A13,13,0,0,0,65,14.2Z" transform="translate(-4 -14.2)" />
// </Svg>
React.createElement(Svg, __assign({}, props, { viewBox: "0 0 84 76" }),
    React.createElement(Path, { fillRule: 'evenodd', clipRule: 'evenodd', d: "M32.7418 75.5H51.2585C64.2627 75.5 70.7668 75.5 75.4377 72.4375C77.453 71.1166 79.1888 69.412 80.546 67.4208C83.6668 62.8375 83.6668 56.45 83.6668 43.6833C83.6668 30.9125 83.6668 24.5292 80.546 19.9458C79.1889 17.9546 77.4531 16.25 75.4377 14.9292C72.4377 12.9583 68.6793 12.2542 62.9252 12.0042C60.1793 12.0042 57.8168 9.96251 57.2793 7.31668C56.8686 5.37863 55.8013 3.64183 54.2579 2.39981C52.7145 1.15778 50.7896 0.486714 48.8085 0.500011H35.1918C31.0752 0.500011 27.5293 3.35418 26.721 7.31668C26.1835 9.96251 23.821 12.0042 21.0752 12.0042C15.3252 12.2542 11.5668 12.9625 8.56266 14.9292C6.54858 16.2503 4.81422 17.9549 3.4585 19.9458C0.333496 24.5292 0.333496 30.9125 0.333496 43.6833C0.333496 56.45 0.333496 62.8333 3.45433 67.4208C4.80433 69.4042 6.53766 71.1083 8.56266 72.4375C13.2335 75.5 19.7377 75.5 32.7418 75.5ZM42.0002 26.6375C32.4127 26.6375 24.6377 34.2667 24.6377 43.6792C24.6377 53.0958 32.4127 60.7292 42.0002 60.7292C51.5877 60.7292 59.3627 53.0958 59.3627 43.6833C59.3627 34.2667 51.5877 26.6375 42.0002 26.6375ZM42.0002 33.4542C36.2502 33.4542 31.5835 38.0333 31.5835 43.6833C31.5835 49.3292 36.2502 53.9083 42.0002 53.9083C47.7502 53.9083 52.4168 49.3292 52.4168 43.6833C52.4168 38.0333 47.7502 33.4542 42.0002 33.4542ZM61.6752 30.0458C61.6752 28.1625 63.2293 26.6375 65.1502 26.6375H69.7752C71.6918 26.6375 73.2502 28.1625 73.2502 30.0458C73.2414 30.9579 72.8709 31.8292 72.2201 32.4683C71.5693 33.1073 70.6914 33.462 69.7793 33.4542H65.1502C64.6982 33.4586 64.2498 33.3739 63.8305 33.205C63.4113 33.0361 63.0294 32.7862 62.7067 32.4698C62.384 32.1533 62.1269 31.7763 61.9499 31.3604C61.7729 30.9445 61.6795 30.4978 61.6752 30.0458Z" }))); };
var Native = Web;
export default { Native: Native, Web: Web };
