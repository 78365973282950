'use-strict';
/**
 * Compute bounding box for the given region
 * @param {Object} region - Google Maps/MapKit region
 * @returns {Object} - Region's bounding box as WSEN array
 */
export var regionToBoundingBox = function (region) {
    var lngD;
    if (region.longitudeDelta < 0)
        lngD = region.longitudeDelta + 360;
    else
        lngD = region.longitudeDelta;
    return ([
        region.longitude - lngD,
        region.latitude - region.latitudeDelta,
        region.longitude + lngD,
        region.latitude + region.latitudeDelta // northLat - max lat
    ]);
};
/**
 * Calculate region from the given bounding box.
 * Bounding box must be represented as WSEN:
 * {
 *   ws: { longitude: minLon, latitude: minLat }
 *   en: { longitude: maxLon, latitude: maxLat }
 * }
 * @param {Object} bbox - Bounding box
 * @returns {Object} - Google Maps/MapKit compliant region
 */
export var boundingBoxToRegion = function (bbox) {
    var minLon = bbox.ws.longitude * Math.PI / 180, maxLon = bbox.en.longitude * Math.PI / 180;
    var minLat = bbox.ws.latitude * Math.PI / 180, maxLat = bbox.en.latitude * Math.PI / 180;
    var dLon = maxLon - minLon, dLat = maxLat - minLat;
    var x = Math.cos(maxLat) * Math.cos(dLon), y = Math.cos(maxLat) * Math.sin(dLon);
    var latRad = Math.atan2(Math.sin(minLat) + Math.sin(maxLat), Math.sqrt((Math.cos(minLat) + x) * (Math.cos(minLat) + x) + y * y)), lonRad = minLon + Math.atan2(y, Math.cos(minLat) + x);
    var latitude = latRad * 180 / Math.PI, longitude = lonRad * 180 / Math.PI;
    return {
        latitude: latitude,
        longitude: longitude,
        latitudeDelta: dLat * 180 / Math.PI,
        longitudeDelta: dLon * 180 / Math.PI
    };
};
export var getCoordinatesFromItem = function (item, accessor, asArray) {
    if (asArray === void 0) { asArray = true; }
    var coordinates = [];
    if (typeof accessor === 'string') {
        coordinates = [item[accessor].longitude, item[accessor].latitude];
    }
    else if (typeof accessor === 'function') {
        coordinates = accessor(item);
    }
    if (asArray) {
        return coordinates;
    }
    return {
        latitude: coordinates[1],
        longitude: coordinates[0]
    };
};
/**
 * Compute a RFC-compliant GeoJSON Feature object
 * from the given JS object
 * RFC7946: https://tools.ietf.org/html/rfc7946#section-3.2
 * @param {Object} item - JS object containing marker data
 * @param {Function|String} accessor - accessor for item coordinate values. Could be a string (field name) or a function (that describe how to access to coordinate data).
 * @returns {Object} - GeoJSON Feature object
 */
export var itemToGeoJSONFeature = function (item, accessor) {
    var coordinates = getCoordinatesFromItem(item, accessor);
    return {
        type: 'Feature',
        geometry: {
            coordinates: coordinates,
            type: 'Point'
        },
        properties: { point_count: 0, item: item } // eslint-disable-line camelcase
    };
};
