// import dm_productos from "./dm_productos";
// import dm_categorias from "./dm_categorias";
// import dm_clientes from "./dm_clientes";
import dm_usuarios from "./dm_usuarios";

import tbemp from "./tbemp";
import tbemt from "./tbemt";
import tbrutadia from "./tbrutadia";
import tbzon from "./tbzon";
import tbprd from "./tbprd";
import tbcli from "./tbcli";
import tbprdlin from "./tbprdlin";
import tbtg from "./tbtg";
import tbalm from "./tbalm";

import carrito from "./carrito";
import tbven from "./tbven";
import tbvd from "./tbvd";
import background_location from "./background_location";
export default {
    // dm_categorias,
    // dm_productos,
    // dm_clientes,
    tbalm,
    dm_usuarios,
    tbcli,
    tbemp,
    tbrutadia,
    tbzon,
    tbemt,
    tbprd,
    tbprdlin,
    tbtg,
    carrito,
    tbven,
    tbvd,
    background_location
}