var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { SText, STheme, SThread, SView } from "../../";
import { Animated, FlatList } from "react-native";
var SSPiner = /** @class */ (function (_super) {
    __extends(SSPiner, _super);
    function SSPiner(props) {
        var _this = this;
        var _a, _b, _c;
        _this = _super.call(this, props) || this;
        _this.state = {
            layout: null,
            scrollOffset: 0,
            currentIndex: 0,
            value: "",
            itemHeight: 50
        };
        _this.startIndex = 0;
        _this.renderAno = function (_a) {
            // const itemCenterPosition = (index * height) + height / 2;
            // const distanceFromCenter = Math.abs(this.state.scrollOffset + this.state.layout.height / 2 - itemCenterPosition);
            var item = _a.item, index = _a.index;
            // const scale = Math.max(1.7 - distanceFromCenter / (this.state.layout.height / 2), 0.5); // Asegura un mínimo de escala
            // Ajusta la escala basada en la distancia al centro
            var inputRange = [
                ((index - 4) * _this.state.itemHeight) + (_this.state.layout.height / 2),
                (index * _this.state.itemHeight) + (_this.state.itemHeight / 2) + (_this.state.layout.height / 2),
                ((index + 4) * _this.state.itemHeight) + (_this.state.layout.height / 2) // ítem siguiente
            ];
            var scale = _this.animated.interpolate({
                inputRange: inputRange,
                outputRange: [0.4, 2.5, 0.4],
                extrapolate: 'clamp'
            });
            var color = _this.animated.interpolate({
                inputRange: inputRange,
                outputRange: [STheme.color.text + "66", STheme.color.text, STheme.color.text + "66"],
                extrapolate: 'clamp'
            });
            return React.createElement(SView, { height: _this.state.itemHeight, center: true, onPress: function () {
                    // this.startIndex = this.array.findIndex(val => val == curval);
                    var offset = ((index * _this.state.itemHeight) + ((_this.state.layout.height / 2)) - _this.state.layout.height / 2 + _this.state.itemHeight / 2);
                    _this.list.scrollToOffset({
                        animated: true,
                        offset: offset > 0 ? offset : 0
                    });
                } },
                React.createElement(SText, { clean: true, animated: true, fontSize: 12, style: {
                        transform: [{ scale: scale }],
                        color: color
                    } }, item));
        };
        _this.state.itemHeight = (_a = _this.props.itemHeight) !== null && _a !== void 0 ? _a : 50;
        _this.state.value = (_b = _this.props.defaultValue) !== null && _b !== void 0 ? _b : "";
        _this.array = (_c = _this.props.options) !== null && _c !== void 0 ? _c : [];
        if (!_this.state.value) {
            _this.state.value = _this.array[Math.round(_this.array.length / 2)];
        }
        var curval = !_this.state.value ? "" : _this.state.value;
        _this.startIndex = _this.array.findIndex(function (val) { return val == curval; });
        _this.animated = new Animated.Value(0);
        return _this;
    }
    SSPiner.prototype.componentDidMount = function () {
        var _this = this;
        new SThread(200, "asdasd", false).start(function () {
            // this.list.scrollToIndex({
            //     animated: false,
            //     index: this.startIndex,
            // })
            // console.log(this.startIndex)
            // console.log(this.startIndex)
            var offset = ((_this.startIndex * _this.state.itemHeight) + ((_this.state.layout.height / 2)) - _this.state.layout.height / 2 + _this.state.itemHeight / 2);
            _this.list.scrollToOffset({
                animated: false,
                offset: offset > 0 ? offset : 0
            });
        });
    };
    SSPiner.prototype.onChangeValue = function () {
        if (this.props.onChange)
            this.props.onChange(this.state.value);
    };
    SSPiner.prototype.render = function () {
        var _this = this;
        return React.createElement(SView, __assign({ col: "xs-12" }, this.props, { onLayout: function (e) {
                _this.setState({ layout: e.nativeEvent.layout });
            }, center: true }),
            React.createElement(SView, { style: {
                    width: 100,
                    height: this.state.itemHeight,
                    position: "absolute",
                    // backgroundColor: STheme.color.card,
                    // opacity: 0.2,
                    borderColor: STheme.color.accent,
                    borderBottomWidth: 2,
                    borderTopWidth: 2
                } }),
            !this.state.layout ? null : React.createElement(FlatList, { style: {
                    width: "100%"
                }, ref: function (ref) { return _this.list = ref; }, data: this.array, initialScrollIndex: this.startIndex, keyExtractor: function (item) { return item.toString(); }, renderItem: this.renderAno, scrollEnabled: true, onScroll: function (event) {
                    // return;
                    _this.animated.setValue(event.nativeEvent.contentOffset.y + (_this.state.layout.height / 2));
                    // this.setState({ scrollOffset: event.nativeEvent.contentOffset.y })
                    _this.state.scrollOffset = event.nativeEvent.contentOffset.y;
                    var index = ((_this.state.scrollOffset + (_this.state.layout.height / 2)) / _this.state.itemHeight) - (((_this.state.layout.height + (_this.state.itemHeight)) / 2) / _this.state.itemHeight);
                    var closestIndex = Math.round(index);
                    // console.log(closestIndex)
                    // return;
                    if (_this.state.currentIndex != closestIndex) {
                        if (!_this.array[closestIndex])
                            return;
                        _this.state.value = _this.array[closestIndex];
                        _this.onChangeValue();
                    }
                    _this.state.currentIndex = closestIndex;
                    new SThread(300, "askdna", true).start(function () {
                        var offset = ((closestIndex - (((_this.state.layout.height - (_this.state.itemHeight)) / 2) / _this.state.itemHeight)) * _this.state.itemHeight) + (_this.state.layout.height / 2);
                        _this.list.scrollToOffset({ animated: true, offset: offset });
                        _this.onChangeValue();
                    });
                    // setScrollOffset(event.nativeEvent.contentOffset.y);
                }, snapToAlignment: "start", decelerationRate: "fast", snapToInterval: this.state.itemHeight * 2, scrollEventThrottle: 16, 
                // maxToRenderPerBatch={100}
                // initialNumToRender={100}
                // windowSize={this.state.layout.height / height}
                ListHeaderComponent: React.createElement(SView, { height: this.state.layout.height / 2 }), ListFooterComponent: React.createElement(SView, { height: this.state.layout.height / 2 }), getItemLayout: function (data, index) { return ({ length: _this.state.itemHeight, offset: index * _this.state.itemHeight, index: index }); } }));
    };
    return SSPiner;
}(React.Component));
export default SSPiner;
