import { SPage } from "servisofts-component";
import Model from "../../../Model/";
import root from "./root";
import tbemp from "./tbemp";

export default SPage.combinePages("profile", {
    "": root,
    tbemp

})
