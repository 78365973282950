var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FlatList, View, ScrollView } from 'react-native';
import React, { Component } from 'react';
import Cell from './Cell';
var STable3 = /** @class */ (function (_super) {
    __extends(STable3, _super);
    function STable3(props) {
        var _this = _super.call(this, props) || this;
        _this.lastMove = new Date().getTime();
        _this.lastEvent = "";
        _this.handleScrollHorizontal = function (e) {
            if (!_this.props.parent.headers)
                return;
            _this.props.parent.headers.scrollTo(__assign({}, e.nativeEvent.contentOffset));
        };
        _this.handleScrollVertical = function (e) {
            if (_this.lastEvent == "scrollTo") {
                _this.lastEvent = "";
                return;
            }
            _this.lastEvent = "handleScrollVertical";
            if (_this.props.parent.rowNumbers) {
                _this.props.parent.rowNumbers.scrollTo(e.nativeEvent.contentOffset.y);
            }
            // if (this.props.parent.scroll) {
            //     this.props.parent.scroll.scrollTo({ ...e.nativeEvent.contentOffset })
            // }
        };
        return _this;
    }
    STable3.prototype.getItemLayout = function (data, index) {
        return { length: this.props.parent.props.rowHeight, offset: this.props.parent.props.rowHeight * index, index: index };
    };
    STable3.prototype.keyExtractor = function (item, index) {
        return "r" + index;
    };
    STable3.prototype.scrollTo = function (e) {
        if (this.lastEvent == "handleScrollVertical") {
            this.lastEvent = "";
            return;
        }
        this.lastEvent = "scrollTo";
        this.scrollVertical.scrollToOffset({ offset: e.y, animated: false });
    };
    STable3.prototype.render = function () {
        var _this = this;
        var Row = React.memo(function (_a) {
            var obj = _a.obj, row = _a.row;
            var values = Object.values(obj !== null && obj !== void 0 ? obj : {});
            return React.createElement(View, { style: { width: _this.props.parent.contentWidth, height: _this.props.parent.props.rowHeight, flexDirection: "row" } }, _this.props.parent.state.cols.map(function (a, i) { return React.createElement(Cell, { value: values[i], row: row, col: { index: i, item: a } }); }));
        }, function (prevProps, nextProps) { return JSON.stringify(prevProps.obj) === JSON.stringify(nextProps.obj); });
        return (React.createElement(ScrollView, { horizontal: true, contentContainerStyle: { width: this.props.parent.contentWidth }, onScroll: this.handleScrollHorizontal, scrollEventThrottle: this.props.parent.scrollEventThrottle },
            React.createElement(FlatList, { ref: function (ref) { return _this.scrollVertical = ref; }, contentContainerStyle: { width: this.props.parent.contentWidth }, onScroll: this.handleScrollVertical, data: this.props.parent.state.rows, getItemLayout: this.getItemLayout.bind(this), scrollEventThrottle: this.props.parent.scrollEventThrottle, showsHorizontalScrollIndicator: false, showsVerticalScrollIndicator: false, windowSize: 31, maxToRenderPerBatch: 10, removeClippedSubviews: true, keyExtractor: this.keyExtractor, renderItem: function (row) { var _a; return React.createElement(Row, { obj: (_a = _this.props.data[row.index]) !== null && _a !== void 0 ? _a : {}, row: row }); } })));
    };
    return STable3;
}(Component));
export default STable3;
