var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import { SDate, SText, STheme, SThread, SView } from "../../";
import { Animated, FlatList } from "react-native";
var height = 50;
var Anos = /** @class */ (function (_super) {
    __extends(Anos, _super);
    function Anos(props) {
        var _this = this;
        var _a;
        _this = _super.call(this, props) || this;
        _this.state = {
            layout: null,
            scrollOffset: 0,
            currentIndex: 0,
            value: ""
        };
        _this.startIndex = 0;
        _this.renderAno = function (_a) {
            // const itemCenterPosition = (index * height) + height / 2;
            // const distanceFromCenter = Math.abs(this.state.scrollOffset + this.state.layout.height / 2 - itemCenterPosition);
            var item = _a.item, index = _a.index;
            // const scale = Math.max(1.7 - distanceFromCenter / (this.state.layout.height / 2), 0.5); // Asegura un mínimo de escala
            // Ajusta la escala basada en la distancia al centro
            var inputRange = [
                ((index - 5) * height) + (_this.state.layout.height / 2),
                (index * height) + (height / 2) + (_this.state.layout.height / 2),
                ((index + 5) * height) + (_this.state.layout.height / 2) // ítem siguiente
            ];
            var scale = _this.animated.interpolate({
                inputRange: inputRange,
                outputRange: [0.7, 2.5, 0.7],
                extrapolate: 'identity'
            });
            var color = _this.animated.interpolate({
                inputRange: inputRange,
                outputRange: [STheme.color.text + "66", STheme.color.text, STheme.color.text + "66"],
                extrapolate: 'clamp'
            });
            return React.createElement(SText, { animated: true, height: height, center: true, fontSize: 12, style: { transform: [{ scale: scale }], color: color } }, item);
        };
        _this.state.value = ((_a = _this.props.defaultValue) !== null && _a !== void 0 ? _a : "") + "";
        var currentYear = !_this.state.value ? new SDate().getYear() : _this.state.value;
        var yearToStart = 1950;
        _this.years = Array.from({ length: 100 }, function (_, index) { return yearToStart + index; });
        _this.startIndex = _this.years.findIndex(function (year) { return year == currentYear; });
        _this.animated = new Animated.Value(0);
        return _this;
    }
    Anos.prototype.componentDidMount = function () {
        var _this = this;
        new SThread(200, "asdasd", false).start(function () {
            // this.list.scrollToIndex({
            //     animated: false,
            //     index: this.startIndex,
            // })
            // console.log(this.startIndex)
            // console.log(this.startIndex)
            var offset = ((_this.startIndex * height) + ((_this.state.layout.height / 2)) - _this.state.layout.height / 2 + height / 2);
            _this.list.scrollToOffset({
                animated: false,
                offset: offset > 0 ? offset : 0
            });
        });
    };
    Anos.prototype.onChangeValue = function () {
    };
    Anos.prototype.render = function () {
        var _this = this;
        return React.createElement(SView, { col: "xs-12", height: true, onLayout: function (e) {
                _this.setState({ layout: e.nativeEvent.layout });
            }, center: true },
            React.createElement(SView, { style: {
                    width: 100,
                    height: height,
                    position: "absolute",
                    // backgroundColor: STheme.color.card,
                    // opacity: 0.2,
                    borderColor: STheme.color.accent,
                    borderBottomWidth: 2,
                    borderTopWidth: 2
                } }),
            !this.state.layout ? null : React.createElement(FlatList, { style: {
                    width: "100%"
                }, ref: function (ref) { return _this.list = ref; }, data: this.years, initialScrollIndex: this.startIndex, keyExtractor: function (item) { return item.toString(); }, renderItem: this.renderAno, scrollEnabled: true, onScroll: function (event) {
                    // return;
                    _this.animated.setValue(event.nativeEvent.contentOffset.y + (_this.state.layout.height / 2));
                    // this.setState({ scrollOffset: event.nativeEvent.contentOffset.y })
                    _this.state.scrollOffset = event.nativeEvent.contentOffset.y;
                    var index = ((_this.state.scrollOffset + (_this.state.layout.height / 2)) / height) - (((_this.state.layout.height + (height)) / 2) / height);
                    var closestIndex = Math.round(index);
                    // console.log(closestIndex)
                    // return;
                    if (_this.state.currentIndex != closestIndex) {
                        if (!_this.years[closestIndex])
                            return;
                        _this.state.value = _this.years[closestIndex];
                        _this.onChangeValue();
                    }
                    _this.state.currentIndex = closestIndex;
                    new SThread(300, "askdna", true).start(function () {
                        var offset = ((closestIndex - (((_this.state.layout.height - (height)) / 2) / height)) * height) + (_this.state.layout.height / 2);
                        _this.list.scrollToOffset({ animated: true, offset: offset });
                        _this.onChangeValue();
                    });
                    // setScrollOffset(event.nativeEvent.contentOffset.y);
                }, snapToAlignment: "start", decelerationRate: "fast", snapToInterval: height * 2, scrollEventThrottle: 16, 
                // maxToRenderPerBatch={100}
                // initialNumToRender={100}
                // windowSize={this.state.layout.height / height}
                ListHeaderComponent: React.createElement(SView, { height: this.state.layout.height / 2 }), ListFooterComponent: React.createElement(SView, { height: this.state.layout.height / 2 }), getItemLayout: function (data, index) { return ({ length: height, offset: index * height, index: index }); } }));
    };
    return Anos;
}(React.Component));
export default Anos;
