'use-strict';
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { Platform, Dimensions, LayoutAnimation } from 'react-native';
// map-related libs
// import MapView from 'react-native-maps'
import SMapView from '..';
import SuperCluster from 'supercluster';
import GeoViewport from '@mapbox/geo-viewport';
// components / views
import ClusterMarker from './ClusterMarker';
// libs / utils
import { regionToBoundingBox, itemToGeoJSONFeature, getCoordinatesFromItem, } from './util';
var ClusteredMapView = /** @class */ (function (_super) {
    __extends(ClusteredMapView, _super);
    function ClusteredMapView(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            data: [],
            region: props.region || props.initialRegion
        };
        _this.isAndroid = Platform.OS === 'android';
        _this.dimensions = [props.width, props.height];
        _this.mapRef = _this.mapRef.bind(_this);
        _this.onClusterPress = _this.onClusterPress.bind(_this);
        _this.onRegionChangeComplete = _this.onRegionChangeComplete.bind(_this);
        return _this;
    }
    ClusteredMapView.prototype.componentDidMount = function () {
        this.clusterize(this.props.data);
    };
    ClusteredMapView.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.data !== nextProps.data)
            this.clusterize(nextProps.data);
    };
    ClusteredMapView.prototype.componentWillUpdate = function (nextProps, nextState) {
        if (!this.isAndroid && this.props.animateClusters && this.clustersChanged(nextState))
            LayoutAnimation.configureNext(this.props.layoutAnimationConf);
    };
    ClusteredMapView.prototype.fitToCoordinates = function (points, options) {
        this.mapview.fitToCoordinates(points, options);
    };
    ClusteredMapView.prototype.mapRef = function (ref) {
        this.mapview = ref;
    };
    ClusteredMapView.prototype.getMapRef = function () {
        return this.mapview;
    };
    ClusteredMapView.prototype.getClusteringEngine = function () {
        return this.index;
    };
    ClusteredMapView.prototype.clusterize = function (dataset) {
        var _this = this;
        this.index = new SuperCluster({
            extent: this.props.extent,
            minZoom: this.props.minZoom,
            maxZoom: this.props.maxZoom,
            radius: this.props.radius || (this.dimensions[0] * .045)
        });
        // get formatted GeoPoints for cluster
        var rawData = dataset.map(function (item) { return itemToGeoJSONFeature(item, _this.props.accessor); });
        // load geopoints into SuperCluster
        this.index.load(rawData);
        var data = this.getClusters(this.state.region);
        this.setState({ data: data });
    };
    ClusteredMapView.prototype.clustersChanged = function (nextState) {
        return this.state.data.length !== nextState.data.length;
    };
    ClusteredMapView.prototype.onRegionChangeComplete = function (region) {
        var _this = this;
        var data = this.getClusters(region);
        this.setState({ region: region, data: data }, function () {
            _this.props.onRegionChangeComplete && _this.props.onRegionChangeComplete(region, data);
        });
    };
    ClusteredMapView.prototype.getClusters = function (region) {
        var bbox = regionToBoundingBox(region), viewport = (region.longitudeDelta) >= 40 ? { zoom: this.props.minZoom } : GeoViewport.viewport(bbox, this.dimensions);
        return this.index.getClusters(bbox, viewport.zoom);
    };
    ClusteredMapView.prototype.onClusterPress = function (cluster) {
        var _this = this;
        // cluster press behavior might be extremely custom.
        if (!this.props.preserveClusterPressBehavior) {
            this.props.onClusterPress && this.props.onClusterPress(cluster.properties.cluster_id);
            return;
        }
        // //////////////////////////////////////////////////////////////////////////////////
        // NEW IMPLEMENTATION (with fitToCoordinates)
        // //////////////////////////////////////////////////////////////////////////////////
        // get cluster children
        var children = this.index.getLeaves(cluster.properties.cluster_id, this.props.clusterPressMaxChildren);
        var markers = children.map(function (c) { return c.properties.item; });
        var coordinates = markers.map(function (item) { return getCoordinatesFromItem(item, _this.props.accessor, false); });
        // fit right around them, considering edge padding
        this.mapview.fitToCoordinates(coordinates, { edgePadding: this.props.edgePadding });
        this.props.onClusterPress && this.props.onClusterPress(cluster.properties.cluster_id, markers);
    };
    ClusteredMapView.prototype.render = function () {
        var _this = this;
        return (React.createElement(SMapView, __assign({}, this.props, { ref: this.mapRef, onRegionChangeComplete: this.onRegionChangeComplete }),
            this.props.clusteringEnabled && this.state.data.map(function (d) {
                if (d.properties.point_count === 0)
                    return _this.props.renderMarker(__assign(__assign({}, d.properties.item), { count: 1 }));
                var properties = d.properties, geometry = d.geometry;
                var latitude = geometry.coordinates[1], longitude = geometry.coordinates[0];
                var cluster = {
                    count: properties.point_count,
                    location: { latitude: latitude, longitude: longitude },
                    id: properties.cluster_id
                };
                return _this.props.renderCluster(cluster, _this.onClusterPress.bind(_this, d));
                return (React.createElement(ClusterMarker, __assign({}, d, { onPress: _this.onClusterPress, renderCluster: _this.props.renderCluster, key: "cluster-".concat(d.properties.cluster_id) })));
            }),
            !this.props.clusteringEnabled && this.props.data.map(function (d) { return _this.props.renderMarker(d); }),
            this.props.children));
    };
    ClusteredMapView.defaultProps = {
        minZoom: 0,
        maxZoom: 30,
        extent: 512,
        accessor: 'location',
        animateClusters: false,
        clusteringEnabled: true,
        clusterPressMaxChildren: 100,
        preserveClusterPressBehavior: true,
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
        layoutAnimationConf: LayoutAnimation.Presets.spring,
        edgePadding: { top: 10, left: 10, right: 10, bottom: 10 }
    };
    return ClusteredMapView;
}(Component));
export default ClusteredMapView;
