var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Svg from "react-native-svg";
import { Path } from "react-native-svg";
var Web = function (props) { return (React.createElement(Svg, __assign({}, props, { viewBox: "0 0 88 75" }),
    React.createElement(Path, { d: "M3.125 75C2.2962 75 1.50134 74.6708 0.915291 74.0847C0.32924 73.4987 0 72.7038 0 71.875V3.125C0 2.2962 0.32924 1.50134 0.915291 0.915292C1.50134 0.329241 2.2962 0 3.125 0H84.375C85.2038 0 85.9987 0.329241 86.5847 0.915292C87.1708 1.50134 87.5 2.2962 87.5 3.125V71.875C87.5 72.7038 87.1708 73.4987 86.5847 74.0847C85.9987 74.6708 85.2038 75 84.375 75H3.125ZM33.9375 52.6875L27.2063 45.9562C26.6202 45.3704 25.8255 45.0413 24.9969 45.0413C24.1682 45.0413 23.3735 45.3704 22.7875 45.9562L6.25 62.5063H82.2937L58.65 34.1312C58.3567 33.7785 57.9894 33.4946 57.5741 33.2997C57.1588 33.1049 56.7056 33.0039 56.2469 33.0039C55.7881 33.0039 55.335 33.1049 54.9197 33.2997C54.5043 33.4946 54.137 33.7785 53.8437 34.1312L38.55 52.4813C38.2718 52.815 37.9271 53.087 37.5378 53.28C37.1485 53.473 36.7233 53.5827 36.2893 53.6021C35.8552 53.6215 35.4219 53.5502 35.017 53.3927C34.612 53.2353 34.2444 52.9951 33.9375 52.6875ZM18.75 25C18.7504 26.2316 18.9934 27.451 19.4651 28.5886C19.9367 29.7263 20.6279 30.7599 21.499 31.6304C22.3701 32.501 23.4042 33.1914 24.5422 33.6623C25.6801 34.1332 26.8997 34.3754 28.1312 34.375C29.3628 34.3746 30.5822 34.1316 31.7199 33.6599C32.8575 33.1883 33.8911 32.4971 34.7617 31.626C35.6322 30.7549 36.3227 29.7208 36.7936 28.5828C37.2645 27.4449 37.5067 26.2253 37.5062 24.9938C37.5054 22.5065 36.5166 20.1215 34.7573 18.3633C32.9979 16.6052 30.6122 15.6179 28.125 15.6188C25.6378 15.6196 23.2527 16.6084 21.4946 18.3677C19.7364 20.1271 18.7492 22.5128 18.75 25Z" }))); };
var Native = Web;
export default { Native: Native, Web: Web };
