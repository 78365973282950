var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Svg from "react-native-svg";
import { Path } from "react-native-svg";
var Web = function (props) { return (React.createElement(Svg, __assign({}, props, { viewBox: "0 0 270 270" }),
    React.createElement(Path, { d: "M224.924 206.833C224.559 211.8 222.324 216.444 218.671 219.829C215.018 223.214 210.217 225.089 205.236 225.076H202.348C142.905 225.076 83.3868 225.076 23.8682 225.076C18.5198 225.774 13.1038 224.445 8.68636 221.35C4.26892 218.255 1.17012 213.618 0 208.353L0 202.5C1.50845 196.718 4.87326 191.59 9.5777 187.905C17.1987 180.955 23.285 172.49 27.4474 163.053C31.6097 153.616 33.7563 143.414 33.75 133.1C33.75 122.686 33.75 112.348 33.75 101.934C33.6033 83.1238 40.2012 64.8835 52.3475 50.5199C64.4938 36.1563 81.3845 26.6201 99.9578 23.6401H101.25C101.25 19.4593 101.25 15.2786 101.25 11.0978C101.25 8.35824 102.257 5.71422 104.079 3.66818C105.901 1.62214 108.411 0.316669 111.132 -0.000153087C113.781 -0.35028 116.467 0.273789 118.69 1.75595C120.913 3.2381 122.522 5.47742 123.218 8.05728C123.577 10.7544 123.78 13.4701 123.826 16.1907C124.098 19.0791 123.315 21.9683 121.622 24.3242C114.082 35.9495 109.331 49.1599 107.74 62.9242C106.148 76.6886 107.759 90.6345 112.446 103.674C117.134 116.713 124.771 128.492 134.762 138.093C144.752 147.694 156.827 154.856 170.042 159.02C178.354 161.651 187.016 163.01 195.735 163.049C196.365 162.953 197.009 163.073 197.561 163.391C198.113 163.709 198.542 164.204 198.775 164.797C203.303 174.47 209.832 183.071 217.931 190.034C220.408 192.025 222.347 194.607 223.569 197.542C224.791 200.477 225.256 203.671 224.924 206.833Z" }),
    React.createElement(Path, { d: "M153.699 236.326C152.838 241.262 150.976 245.97 148.226 250.16C144.985 255.418 140.618 259.891 135.439 263.256C130.26 266.621 124.398 268.794 118.277 269.62C117.853 269.675 117.441 269.804 117.061 270H108.091C102.679 268.648 97.3897 266.843 92.2804 264.603C86.8597 261.78 82.1449 257.772 78.4864 252.877C74.8279 247.981 72.3199 242.324 71.1487 236.326H153.699Z" }),
    React.createElement(Path, { d: "M197.103 -0.00013366C182.64 -0.0451652 168.489 4.19948 156.439 12.197C144.389 20.1946 134.98 31.5859 129.404 44.9304C123.828 58.2749 122.334 72.9733 125.111 87.1669C127.889 101.36 134.812 114.412 145.007 124.67C155.202 134.929 168.21 141.934 182.386 144.8C196.562 147.665 211.269 146.263 224.648 140.77C238.027 135.277 249.477 125.94 257.549 113.94C265.622 101.94 269.955 87.8156 270 73.3529C270.03 63.75 268.168 54.2352 264.521 45.3518C260.874 36.4684 255.513 28.3903 248.744 21.5788C241.974 14.7674 233.93 9.35588 225.07 5.65335C216.209 1.95082 206.706 0.0297664 197.103 -0.00013366Z" }))); };
var Native = Web;
export default { Native: Native, Web: Web };
