'use-strict';
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// base libs
import PropTypes from 'prop-types';
import React, { Component } from 'react';
var ClusterMarker = /** @class */ (function (_super) {
    __extends(ClusterMarker, _super);
    function ClusterMarker(props) {
        var _this = _super.call(this, props) || this;
        _this.onPress = _this.onPress.bind(_this);
        return _this;
    }
    ClusterMarker.prototype.onPress = function () {
        this.props.onPress(this.props);
    };
    ClusterMarker.prototype.render = function () {
        var pointCount = this.props.properties.point_count; // eslint-disable-line camelcase
        var latitude = this.props.geometry.coordinates[1], longitude = this.props.geometry.coordinates[0];
        if (this.props.renderCluster) {
            var cluster = {
                pointCount: pointCount,
                coordinate: { latitude: latitude, longitude: longitude },
                clusterId: this.props.properties.cluster_id
            };
            return this.props.renderCluster(cluster, this.onPress);
        }
        throw "Implement renderCluster method prop to render correctly cluster marker!";
    };
    return ClusterMarker;
}(Component));
export default ClusterMarker;
ClusterMarker.propTypes = {
    renderCluster: PropTypes.func,
    onPress: PropTypes.func.isRequired,
    geometry: PropTypes.object.isRequired,
    properties: PropTypes.object.isRequired
};
