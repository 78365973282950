var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import SView from '../SView';
import SIcon from '../SIcon';
import xlsx from "xlsx-color";
var ExcelExport = /** @class */ (function (_super) {
    __extends(ExcelExport, _super);
    function ExcelExport() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExcelExport.prototype.handleExport = function () {
        console.log("Exportando a excel");
        var workbook = xlsx.utils.book_new();
        var name = "ExcelTest";
        var worksheet = xlsx.utils.aoa_to_sheet([
            ["SERVISOFTS SRL"],
        ]);
        xlsx.utils.sheet_add_aoa(worksheet, [
            [{ v: 10, t: "n", s: { numFmt: '"BOB "#.###,00' } }],
            [{ v: 20, t: "n" }],
            [{ v: 15, t: "n" }],
            [{ t: "n", f: "SUM(B3:B5)" }],
        ], { origin: "B3" });
        xlsx.utils.book_append_sheet(workbook, worksheet, "Hoja1");
        xlsx.writeFile(workbook, name + ".xlsx");
    };
    ExcelExport.prototype.render = function () {
        return (React.createElement(SView, { col: "xs-12", flex: true, onPress: this.handleExport.bind(this) },
            React.createElement(SIcon, { name: 'Excel' })));
    };
    return ExcelExport;
}(Component));
export default ExcelExport;
