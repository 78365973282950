var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import SNotification from '.';
import NotificationItem from './NotificationItem';
import { FlatList, View } from 'react-native';
import SNavigation from '../SNavigation';
var SeparatorItem = React.createElement(View, { style: {
        width: "100%",
        height: 4
    } });
var SNotificationContainer = /** @class */ (function (_super) {
    __extends(SNotificationContainer, _super);
    function SNotificationContainer(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            data: {}
        };
        SNotification._setInstance(_this);
        return _this;
    }
    SNotificationContainer.prototype.repaint = function () {
        this.setState(__assign({}, this.state));
    };
    SNotificationContainer.prototype.componentDidMount = function () {
        SNotification._setInstance(this);
    };
    SNotificationContainer.prototype.render = function () {
        var limit = 5;
        var arr = Object.values(this.state.data);
        // const arr = Object.values(this.state.data).sort((a: Notification, b: Notification) => {
        //     if (a.type != b.type) {
        //         if (a.type == "loading") return 1;
        //         if (b.type == "loading") return -1;
        //     }
        //     return 0;
        // });
        return React.createElement(View, { style: {
                position: "absolute",
                top: 29,
                right: 8
            } },
            React.createElement(FlatList, { data: arr.reverse().slice(0, limit), scrollEnabled: false, ItemSeparatorComponent: function () { return SeparatorItem; }, renderItem: function (_a) {
                    var item = _a.item, index = _a.index;
                    return React.createElement(NotificationItem, { key: item.key, data: item, index: index, onPress: function () {
                            if (item.deeplink) {
                                SNavigation.INSTANCE.openDeepLink(item.deeplink);
                            }
                            SNotification.remove(item.key);
                        } });
                } }),
            arr.length > limit ? React.createElement(React.Fragment, null,
                SeparatorItem,
                React.createElement(NotificationItem, { index: limit, onPress: function () {
                        SNotification.removeAll();
                    }, data: {
                        title: "".concat(arr.length - limit, " +")
                    } })) : null);
    };
    return SNotificationContainer;
}(Component));
export default SNotificationContainer;
